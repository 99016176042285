import { DayEventHandler } from '@oberoninternal/travelbase-ds/components/calendar/PlannerMonth';
import Period from '@oberoninternal/travelbase-ds/entities/Period';
import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import validInterval from '@oberoninternal/travelbase-ds/utils/validInterval';
import compareAsc from 'date-fns/compareAsc';
import isSameDay from 'date-fns/isSameDay';
import { useCallback, useMemo, useReducer } from 'react';
import useOnEscape from '../useOnEscape';

export interface PlannerState {
    period?: Period;
    selecting?: boolean;
}

const initialPeriodState: PlannerState = {
    selecting: false,
    period: null,
};

interface DefaultAction {
    type: 'reset' | 'set' | 'clear';
    state?: PlannerState;
}

interface EventAction {
    type: 'click' | 'hover';
    day: Date;
}

type Action = DefaultAction | EventAction;

const dayIsPeriodDay = (day: Date, period: Interval) => isSameDay(period.start, day) || isSameDay(period.end, day);

function periodReducer(state: PlannerState = initialPeriodState, action: Action): PlannerState {
    const { period, selecting } = state;
    switch (action.type) {
        case 'click': {
            const { day } = action;
            if (!period || (validInterval(period) && !dayIsPeriodDay(day, period) && !selecting)) {
                return {
                    ...state,
                    period: { start: day, end: undefined },
                    selecting: true,
                };
            }
            if (period.start && isSameDay(period.start, day)) {
                return {
                    ...state,
                    period: null,
                    selecting: false,
                };
            }
            if (period.end && isSameDay(period.end, day) && !selecting) {
                return {
                    ...state,
                    selecting: true,
                };
            }
            return {
                ...state,
                period: { ...period, end: day },
                selecting: false,
            };
        }
        case 'hover': {
            const { day } = action;
            if (selecting && period?.start) {
                const [start, end] = [period.start, day].sort(compareAsc);
                return {
                    ...state,
                    period: { start, end },
                };
            }
            return state;
        }
        case 'set':
            return { ...state, ...action.state };
        case 'clear':
            return initialPeriodState;
        case 'reset':
            return { ...initialPeriodState, ...action.state };
        default:
            throw new UnreachableCaseError(action);
    }
}

const usePlannerPeriod = (optionalInitialPeriod?: Period) => {
    const initialPeriod = useMemo(
        () => (optionalInitialPeriod ? { period: optionalInitialPeriod } : initialPeriodState),
        [optionalInitialPeriod]
    );
    const [state, dispatch] = useReducer(periodReducer, initialPeriod);

    const set = useCallback((newState: PlannerState) => {
        dispatch({ state: newState, type: 'set' });
    }, []);

    const reset = useCallback(() => {
        dispatch({ state: initialPeriod, type: 'reset' });
    }, [initialPeriod]);

    const clear = useCallback(() => {
        dispatch({ type: 'clear' });
    }, []);

    const onClick: DayEventHandler = useCallback(({ day }) => dispatch({ type: 'click', day }), []);
    const onHover: DayEventHandler = useCallback(({ day }) => dispatch({ type: 'hover', day }), []);

    useOnEscape(() => {
        if (state.selecting) {
            clear();
        }
    });

    return {
        state,
        onClick,
        onHover,
        reset,
        set,
        clear,
        initialState: initialPeriod,
    };
};

export default usePlannerPeriod;

export type PlannerHook = ReturnType<typeof usePlannerPeriod>;
