import { UnreachableCaseError } from '@oberoninternal/travelbase-ds/entities/UnreachableCaseError';
import React, { ComponentPropsWithRef } from 'react';
import styled, { css } from 'styled-components';
import SvgButton from './designsystem/SvgButton';

const CloseButton = (props: ComponentPropsWithRef<'button'> & { variant?: 'regular' | 'small' }) => (
    <StyledCloseButton type="button" {...props}>
        <Cross />
    </StyledCloseButton>
);

export default CloseButton;

export const StyledCloseButton = styled(SvgButton)<{ variant?: 'regular' | 'small' }>`
    position: absolute;

    ${({ variant = 'regular' }) => {
        switch (variant) {
            case 'regular':
                return css`
                    top: 2.4rem;
                    right: 2.4rem;
                `;
            case 'small':
                return css`
                    top: 1.2rem;
                    right: 1.2rem;
                `;
            default:
                throw new UnreachableCaseError(variant);
        }
    }};

    height: 2.4rem;
    width: 2.4rem;
`;

// TODO: Somehow conflicting ID's mess up svg's, so for now we define the Cross like this.
const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor">
        <defs>
            <filter
                id="xaxaxaxaxaaXAXAX"
                width="147.1%"
                height="200%"
                x="-23.6%"
                y="-40.9%"
                filterUnits="objectBoundingBox"
            >
                <feOffset dy="24" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="40" />
                <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0.229949097 0 0 0 0 0.461254077 0 0 0 0 0.62647192 0 0 0 0.0533333333 0"
                />
                <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter2" />
                <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="12" />
                <feColorMatrix
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"
                    values="0 0 0 0 0.231372549 0 0 0 0 0.462745098 0 0 0 0 0.62745098 0 0 0 0.0266666667 0"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="shadowMatrixOuter2" />
                </feMerge>
            </filter>
        </defs>
        <g fill="none">
            <g transform="translate(-511 -19)">
                <use fill="#000" filter="url(#xaxaxaxaxaaXAXAX)" />
                <use fill="#FFF" />
            </g>
            <path stroke="currentColor" d="m1.5 16.5 15-15m0 15-15-15" />
        </g>
    </svg>
);
