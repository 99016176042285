import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useRef, useState } from 'react';
import { PopperProps } from 'react-popper';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import CloseButton from '../CloseButton';

const Popper = dynamic<PopperProps>(() => import('react-popper').then(mod => mod.Popper), { ssr: false });

export interface PopoverProps extends Omit<PopperProps, 'children'>, Pick<Sesame, 'open' | 'onClose'> {
    transform?: { from: string; to: string };
    className?: string;
}

const Popover: FC<React.PropsWithChildren<PopoverProps>> = ({
    onClose,
    open,
    children,
    className,
    transform = { from: 'translateY(0rem)', to: 'translateY(-2.4rem)' },
    ...props
}) => {
    const { from, to } = transform;
    const transitions = useTransition(open, {
        from: { opacity: 0, transform: from },
        enter: { opacity: 1, transform: to },
        leave: { opacity: 0, transform: from },
    });
    const closeRef = useRef<HTMLButtonElement>(null);
    const [hasFocus, setHasFocus] = useState(false);

    const onFocus = () => setHasFocus(true);

    // For a11y reasons focus the close button after opening
    useEffect(() => {
        if (open && !hasFocus) {
            closeRef.current?.focus();
        }
        if (!open) {
            setHasFocus(false);
        }
    }, [open, hasFocus]);

    return (
        <>
            {transitions(
                (styleProps, item) =>
                    item && (
                        <Popper {...props}>
                            {({ ref, style, placement }) => (
                                <PopperContent ref={ref} style={style} data-placement={placement} open={open}>
                                    <AnimatedContainer style={styleProps} className={className}>
                                        <CloseButton onClick={onClose} ref={closeRef} onFocus={onFocus} />
                                        {children}
                                    </AnimatedContainer>
                                </PopperContent>
                            )}
                        </Popper>
                    )
            )}
        </>
    );
};

export default Popover;

const PopperContent = styled.div<{ open: boolean }>`
    z-index: ${({ theme }) => theme.zIndices.popover};
    ${({ open }) =>
        !open &&
        css`
            pointer-events: none;
        `}
`;

const Container = styled.div`
    padding: 2.4rem;
    background: ${({ theme }) => theme.colors.neutral['0']};
    box-shadow: 0 1.6rem 2.4rem 0 rgba(59, 118, 160, 0.03), 0 2.4rem 8rem 0 rgba(59, 118, 160, 0.05);
    border-radius: 0.8rem;

    width: 100vw;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 33rem;
    }
`;

const AnimatedContainer = animated(Container);
