import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';
import Package from '@oberoninternal/travelbase-ds/svg/Package.svg';
import Special from '@oberoninternal/travelbase-ds/svg/Special.svg';
import { Flex } from '@rebass/grid';
import dynamic from 'next/dynamic';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { ManagerProps, ReferenceProps } from 'react-popper';
import styled, { css } from 'styled-components';
import { CLASS_STICKY_BAR } from '../constants/customizationClassNames';
import { Booking } from '../entities/Booking';
import { getTripDuration } from '../utils/trip';
import BookingText from './BookingText';
import Stack from './Stack';
import StickyBar, {
    StickyBarOriginalPrice,
    StickyBarPrice,
    StickyBarPriceContainer,
    StickyBarSvgButton,
    StickyBarTextButton,
} from './StickyBar';
import StickyBarBalloon from './StickyBarBalloon';
import InformationCircle from './svg/InformationCircle.svg';

const Manager = dynamic<ManagerProps>(() => import('react-popper').then(mod => mod.Manager));
const Reference = dynamic<ReferenceProps>(() => import('react-popper').then(mod => mod.Reference));

interface Props {
    booking: Booking;
    plannerIsOpen?: boolean;
    confirmButton: ReactNode;
    openModal?: () => void;
    className?: string;
    withTransition?: boolean;
    loading?: boolean;
    hasRelativePosition?: boolean;
}

const UnitStickyBar: FC<React.PropsWithChildren<Props>> = ({
    booking,
    plannerIsOpen,
    children,
    openModal,
    confirmButton,
    className,
    withTransition = false,
    loading,
    hasRelativePosition,
}) => {
    const { trip } = booking;

    return (
        <StyledStickyBar
            className={`${className ?? ''} ${CLASS_STICKY_BAR}`}
            plannerIsOpen={plannerIsOpen}
            visible={!!trip}
            withTransition={withTransition}
            hasRelativePosition={hasRelativePosition}
        >
            {children}
            <Flex
                alignItems="center"
                ml={[null, null, 'auto']}
                width={[1, null, 'auto']}
                justifyContent={['space-between', null, 'flex-start']}
            >
                {loading && (
                    <Stack pr="2rem" alignItems={[null, null, 'flex-end']}>
                        <Skeleton width={150} height={25} />
                        <Skeleton width={200} height={15} />
                    </Stack>
                )}
                <TripContent booking={booking} plannerIsOpen={plannerIsOpen} openModal={openModal} loading={loading} />
                {confirmButton}
            </Flex>
        </StyledStickyBar>
    );
};

export const TripContent: FC<Pick<Props, 'booking' | 'loading' | 'plannerIsOpen' | 'openModal'>> = ({
    booking,
    plannerIsOpen,
    openModal,
    loading,
}) => {
    const { arrivalDate, departureDate, trip } = booking;
    const {
        open,
        onToggle,
        onClose,
        ref: outsideClickRef,
    } = useSesame(false, {
        closeOnEscape: true,
        closeOnClickOutside: true,
    });
    const theme = useThemeContext();
    return (
        <>
            {!loading && arrivalDate && departureDate && trip && (
                <Flex
                    pr="2rem"
                    flexDirection="column"
                    alignItems={[null, null, 'flex-end']}
                    style={{ position: 'relative' }}
                    ref={outsideClickRef}
                >
                    <Manager>
                        <Reference>
                            {({ ref }) => (
                                <StickyBarPriceContainer
                                    ref={ref}
                                    alignItems="center"
                                    data-cy="stickyBarPriceContainer"
                                >
                                    <Body variant="small" style={{ marginRight: '4px' }}>
                                        <span className="gt-s">
                                            <FormattedMessage
                                                defaultMessage={`{duration, number} {duration, plural, one {nacht} other {nachten}} voor `}
                                                values={{
                                                    duration: getTripDuration(arrivalDate, departureDate),
                                                }}
                                            />
                                        </span>
                                        {plannerIsOpen && (
                                            <>
                                                <span className="gt-xs lt-s">
                                                    <FormattedMessage
                                                        defaultMessage={`{duration, number} {duration, plural, one {nacht} other {nachten}} voor `}
                                                        values={{
                                                            duration: getTripDuration(arrivalDate, departureDate),
                                                        }}
                                                    />
                                                    <br />
                                                </span>
                                            </>
                                        )}
                                        {trip.originalPrice && (
                                            <>
                                                {' '}
                                                <StickyBarOriginalPrice>
                                                    <FormattedNumber
                                                        format="EUR"
                                                        value={trip.originalPrice}
                                                        minimumFractionDigits={2}
                                                    />
                                                </StickyBarOriginalPrice>
                                            </>
                                        )}
                                        <StickyBarPrice>
                                            {' '}
                                            <FormattedNumber
                                                format="EUR"
                                                value={trip.price}
                                                minimumFractionDigits={2}
                                            />
                                        </StickyBarPrice>

                                        {/* {renderIconButton('lt-s')} */}
                                    </Body>
                                    {trip ? (
                                        <StickyBarSvgButton type="button" onClick={onToggle} onKeyDown={onToggle}>
                                            {trip.originalPrice && <Special color={theme.colors.primary['80']} />}
                                            {trip.specialId && !trip.originalPrice && (
                                                <Package color={theme.colors.primary['80']} />
                                            )}

                                            {!trip.specialId && !trip.originalPrice && <InformationCircle />}
                                        </StickyBarSvgButton>
                                    ) : null}
                                </StickyBarPriceContainer>
                            )}
                        </Reference>
                        <StickyBarBalloon trip={trip} open={open} onClose={onClose} />
                    </Manager>
                    <Stack spacing={2} variant="inline" alignItems="baseline">
                        <Body variant="small" className="gt-xl">
                            <BookingText booking={{ ...booking, type: 'static' }} />
                        </Body>
                        <Body variant="small" className="gt-s lt-xl">
                            <BookingText onlyShowPersons booking={{ ...booking, type: 'static' }} />
                        </Body>

                        {!plannerIsOpen && (
                            <StickyBarTextButton size="tiny" onClick={openModal} showBorderAlways={false}>
                                <span className="gt-s">
                                    <FormattedMessage defaultMessage="aanpassen" />
                                </span>
                                <span className="lt-s">
                                    <BookingText booking={{ ...booking, type: 'static' }} />
                                </span>
                            </StickyBarTextButton>
                        )}
                    </Stack>
                </Flex>
            )}
        </>
    );
};

export default UnitStickyBar;

const StyledStickyBar = styled(StickyBar)<{ plannerIsOpen?: boolean; visible: boolean; withTransition: boolean }>`
    ${({ plannerIsOpen, withTransition, visible }) =>
        withTransition
            ? css`
                  @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
                      transform: ${plannerIsOpen && visible
                          ? 'translateY(0rem)'
                          : 'translateY(calc(var(--booking-bar-height) + 2px))'};
                  }
                  transform: ${plannerIsOpen
                      ? 'translateY(0rem)'
                      : 'translateY(calc(var(--booking-bar-height) + 2px))'};
              `
            : css`
                  @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
                      transform: ${!visible && plannerIsOpen
                          ? 'translateY(var(--booking-bar-height))'
                          : 'translateY(0rem)'};
                      transition: transform 200ms ease-in-out;
                  }
              `};

    z-index: ${({ theme, plannerIsOpen }) => (plannerIsOpen ? theme.zIndices.modal + 1 : theme.zIndices.sticky)};
`;
