import { defineMessages } from 'react-intl';

const pricingStructureMessages = defineMessages({
    accommodation: {
        defaultMessage: 'Verblijfskosten',
    },
    lastMinuteDiscount: {
        defaultMessage: 'Lastminute korting',
    },
    earlyDiscount: {
        defaultMessage: 'Vroegboekkorting',
    },
    earlyDiscountDescription: {
        defaultMessage: 'Met de vroegboekkorting pak je veel voordeel al heel vroeg boekt.',
    },
    lastMinuteDiscountDescription: {
        defaultMessage: 'Met de lastminute korting pak je veel voordeel doordat je op het laatste moment boekt.',
    },
    touristTax: {
        defaultMessage: 'Toeristenbelasting',
    },
    bookingFee: {
        defaultMessage: 'Reserveringskosten',
    },
    cancellationInsurance: {
        defaultMessage: 'Annuleringsverzekering',
    },
    policyCosts: {
        defaultMessage: 'Administratiekosten',
    },
    contentsInsurance: {
        defaultMessage: 'Inventarisverzekering',
    },
    insuranceTax: {
        defaultMessage: 'Verzekeringsbelasting',
    },
});

export default pricingStructureMessages;
