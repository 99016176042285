import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import pricingStructureMessages from '../constants/pricingStructureMessages';
import { PlannerTripFragment, TripTypeEnum, useSpecialQuery } from '../generated/graphql';
import Popover from './designsystem/Popover';
import Stack from './Stack';

interface Props extends Pick<Sesame, 'open' | 'onClose'> {
    trip?: PlannerTripFragment;
}

const StickyBarBalloon: FC<React.PropsWithChildren<Props>> = ({ trip, open, onClose }) => {
    // query will be skipped if there's no specialId, hence the assertion
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, loading } = useSpecialQuery({ variables: { id: trip?.specialId! }, skip: !trip?.specialId });
    const { description, name, landingPageUrl } = data?.special ?? {};
    if (!trip || loading) {
        return null;
    }

    const { type } = trip;

    return (
        <StyledPopover placement="top" open={open} onClose={onClose}>
            {type === TripTypeEnum.Regular && (
                <>
                    <ModalHeader variant="small">
                        <FormattedMessage defaultMessage="Prijs inclusief" />
                    </ModalHeader>
                    <Stack mt={4} spacing={4} style={{ textAlign: 'center' }}>
                        <Body>
                            <FormattedMessage defaultMessage="Verplichte bijkomende kosten" />
                        </Body>
                        <Body>
                            <FormattedMessage {...pricingStructureMessages.touristTax} />
                        </Body>
                        <Body>
                            <FormattedMessage {...pricingStructureMessages.bookingFee} />
                        </Body>
                    </Stack>
                </>
            )}
            {type === TripTypeEnum.LastMinute && (
                <>
                    <ModalHeader variant="small">
                        <FormattedMessage {...pricingStructureMessages.lastMinuteDiscount} />
                    </ModalHeader>
                    <Stack mt={4} spacing={4}>
                        <Body>
                            <FormattedMessage {...pricingStructureMessages.lastMinuteDiscountDescription} />
                        </Body>
                    </Stack>
                </>
            )}
            {type === TripTypeEnum.EarlyBooking && (
                <>
                    <ModalHeader variant="small">
                        <FormattedMessage {...pricingStructureMessages.earlyDiscount} />
                    </ModalHeader>
                    <Stack mt={4} spacing={4}>
                        <Body>
                            <FormattedMessage {...pricingStructureMessages.earlyDiscountDescription} />
                        </Body>
                    </Stack>
                </>
            )}
            {type === TripTypeEnum.Special && (
                <>
                    <ModalHeader variant="small">{name}</ModalHeader>
                    <Stack mt={4} spacing={4}>
                        <Body>
                            {description}{' '}
                            {landingPageUrl && (
                                <>
                                    <br />
                                    <a href={landingPageUrl} target="_blank" rel="noopener noreferrer">
                                        <FormattedMessage defaultMessage="Lees meer" />
                                    </a>
                                </>
                            )}
                        </Body>
                    </Stack>
                </>
            )}
        </StyledPopover>
    );
};

export default StickyBarBalloon;

const StyledPopover = styled(Popover)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        max-width: 80vw;
    }
`;
